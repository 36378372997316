




























import { ChartTab } from "@/models/utils/c-chart.interface";
import { Component, Prop, Vue } from "vue-property-decorator";
import { StampsStatistic } from "@/models/entities/stamps-statistic.interface";

const CChart = () =>
  import(/* webpackChunkName: "CChart" */ "./components/CChart.vue");

@Component({
  components: {
    "c-chart": CChart,
  },
})
export default class CChartArea extends Vue {
  @Prop({ required: true, type: Array })
  private items: StampsStatistic[];
  @Prop({ required: false, default: false, type: Boolean })
  private showChart: boolean;

  private activeTab: ChartTab = ChartTab.LAST_WEEK;
  private chartTabs: { id: ChartTab; value: string }[] = [
    { id: ChartTab.LAST_WEEK, value: "Ultimi 7 gg" },
    { id: ChartTab.LAST_MONTH, value: "Ultimi 30 gg" },
    { id: ChartTab.LAST_YEAR, value: "Ultimo anno" },
  ];

  private onTabClick(tab: ChartTab): void {
    this.activeTab = tab;
    this.$emit("on-chart-tab-click", tab);
  }
}
